import { Button, Dialog, DialogActions, DialogContent, Grid, Paper, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useThemeContext } from "app/ThemeProvider";
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import './custom.css';
import BarChart from "./BarChart";
import MeterDetail from "app/views/depoView/components/meter-detail/MeterDetail";
import { Span } from "app/components/Typography";

import CloseIcon from '@mui/icons-material/Close';



//   const classes = style();

export const MeterDetailDialog = (props = {}) => {
    const [open, setOpen] = useState(false);
    const [row, setRow] = useState(props.element);
    const { darkTheme } = useThemeContext();

    const handleClickOpen = () => {
        setRow(props.row)
        setOpen(true);
    };

    const handleClose = () => {
        setRow({})
        setOpen(false);
    };

    // const style = makeStyles({
    //   titleItemRight: {
    //     float: 'right',
    //     position: 'relative',
    //     transform: 'translateY(-50%)',
    //   },
    // });

    // const classes = style();

    return (
        <span style={{width:"100%",height:'5rem'}}>
            <Tooltip
            // title={props.row.powerSource === 'Backup Power' ? props.row.powerSource : ""}
            >
                {/* 
                <ElectricMeterIcon
                    onClick={handleClickOpen}
                    fontSize="large" /> */}

                <Grid container alignItems="center" justifyContent="center" style={{width:"100%",padding:0}}>
                    <img 
                    onClick={handleClickOpen}
                    src="/meter_logo.jpg" width="60%" 
                    style={{ display: 'block', margin: 'auto',background: "#e7e7e7",
                        borderRadius: "10px",
                        }} />
                </Grid>


            </Tooltip>

            <Dialog

                maxWidth="xl"
                open={open}
                onClose={handleClose}
                fullWidth
            >
                <Paper style={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9' }}>

                    {/* <DialogTitle>Dialog Title</DialogTitle> */}

                    <DialogActions style={{ margin: 0, padding: 0 }}>
                        {/* <Button
                            color="info"
                            alignItems="right"
                            // className={classes.titleItemRight}
                            variant="contained"
                            type="button"
                            onClick={handleClose}
                            style={{
                                background: '#e0e0e0',
                                color: 'black',
                                borderRadius: '3px',
                                padding: '5px 7px',
                                margin: '15px',
                            }}
                        > */}

                        {/* <Span sx={{ textTransform: 'capitalize' }}>Close</Span> */}
                        {/* </Button> */}
                        <CloseIcon onClick={handleClose} />
                    </DialogActions>


                    <DialogContent style={{ margin: 0, padding: '10px' }}>

                        <MeterDetail row={props.element} open={open} />

                    </DialogContent>

                </Paper>
            </Dialog>
        </span>
    );

}





const Charger = ({ element, index, darkTheme }) => {

    console.log(element);

    const [borderColor, setBorderColor] = useState("transparent");
    const [borderType, setBorderType] = useState("dotted");

    useEffect(() => {
        setBorderColor((prevColor) =>
            prevColor === "transparent" ? "green" : "transparent"
        );
        const intervalId = setInterval(() => {
            element.status === "Charging" ? setBorderType("moving-border") : setBorderType("border")
            setBorderColor((prevColor) =>
                prevColor === "transparent" ? "green" : "transparent"
            );
            // setBorderType((prevType)=>prevType==="dotted"?"dashed":"dotted");

        }, 10000);
        return () => clearInterval(intervalId);
    }, []);

    const Details = () => {
        return (<Grid container>
            <Grid item md={12}>
                <p><b>Transformer: </b>{element.bay_name}</p>
                <p><b>Charger ID: </b>{element.charger_id}</p>
                {/* <p><b>Connector ID: Connector IDs</b></p> */}
                {/* <p><b> Vehicle Id : </b>{element.vehicle_id}</p> */}
                <p><b>Vehicle License Plate : </b>{element.vehicleLicensePlate}</p>

                <p><b>Target SOC: </b>{element.target_soc} %</p>
                <p><b>Power: </b>{element.charger_rating} kW</p>
                <p><b>Status: </b>{element.status}</p>


            </Grid>
        </Grid>);
    }

    const getBorderClass = (element) => {
        return element.status === "Charging" ? "moving-border" : !darkTheme ? "border" : "border-dark"


    }
    // border-dark


    const statusColor = (element) => {
        if (element.status.includes("Charging"))
            return '#008001';
        if (element.status.includes("Preparing"))
            return 'rgb(218, 165, 32)';
        if (element.status.includes("Finishing"))
            return 'rgb(218, 165, 32)';
        else if (element.status.includes("Reserved"))
            return 'rgb(75, 192, 192)';
        else if (element.status.includes("Unavailable"))
            return '#878e98';
        else if (element.status.includes("Fault"))
            return '#ff5633';
        return '#4169e1';
    }

    return (
        <div className="charger" style={{ paddingRight: "5px", paddingLeft: "5px" }} >
            <Grid container >
                <Grid item md={12} lg={12} xs={12}>

                    <Grid item lg={12} md={12} xs={12} container justifyContent="center" alignItems="center">

                        <Grid className={getBorderClass(element)} style={{ height: "15px" }}/>

                    </Grid>
                    <Grid item lg={12} md={12} xs={12} container justifyContent="center" alignItems="center">

                        {
                            element.meter_id !== null ? <MeterDetailDialog element={element} />

                                : <Grid className={getBorderClass(element)} style={{ height: "5rem" }} />

                        }



                    </Grid>

                    <Grid item lg={12} md={12} xs={12} container justifyContent="center" alignItems="center">

                        <Grid className={getBorderClass(element)} />

                    </Grid>
                    <Grid item key={index} sm={12} md={12} xs={12}>
                        <Tooltip title={<Details />}>
                            <Paper
                                style={
                                    element.status === "Charging"
                                        ? {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "150px",
                                            background: "#e7e7e7",
                                            padding: "4px 2px",
                                            borderRadius: "10px",
                                            border: `2px solid ${borderColor}`, // Border color changes based on the state
                                            transition: "border-color 0.5s ease", // CSS transition for the border color change,
                                            // backgroundColor : darkTheme? '#808080' :'white'
                                        }
                                        : {
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                            width: "100%",
                                            height: "150px",
                                            background: "#e7e7e7",
                                            padding: "4px 2px",
                                            borderRadius: "10px",
                                            // backgroundColor : darkTheme? '#333333' :'white' // color the charger bg themselves
                                        }
                                }
                                elevation={2}
                            >
                                <div>
                                    {/* {element.status !== "Available" && (
                                        <p style={{ margin: 0, fontSize: "9px", fontWeight: "500" }}>
                                            Vehicle : {element.vehicle_id}
                                            
                                        </p>
                                    )} */}
                                    <p style={{ margin: 0, fontSize: "9px", fontWeight: "500" }}>
                                        Power kW: {element.charger_rating}
                                    </p>

                                    {element.status === "Charging" && (
                                        <p
                                            style={{ margin: 0, fontSize: "9px", fontWeight: "500", textAlign: "center" }}

                                        >
                                            {/* Vehicle:{element.vehicle_id}<br/> */}
                                            Vehicle:{element.vehicleLicensePlate}<br />
                                            {/* RT:0 mins */}

                                            {/* Current SoC: {element.current_soc}% */}
                                        </p>
                                    )}

                                    {element.status === "Charging" && (
                                        <p
                                            style={{ margin: 0, fontSize: "9px", fontWeight: "500" }}
                                        >
                                            {/* Target SoC: {element.target_soc}% */}
                                            {/* Current SoC: {element.current_soc}% */}
                                        </p>
                                    )}
                                </div>
                                <div style={{ alignItems: "center", alignContent: "center", textAlign: "center" }}>
                                    <img width={"70%"} src={`/bus_icons/${element.bus_image}`} alt="bus_88.png" />
                                    <p
                                        style={{
                                            margin: 0,
                                            fontSize: "14px",
                                            fontWeight: "500",

                                            color: (() => statusColor(element))()
                                            ,
                                        }}
                                    >
                                        {element.status}
                                    </p>
                                </div>

                            </Paper>
                        </Tooltip>
                    </Grid>

                </Grid>
            </Grid>
        </div>
    );
}




const ChargerTree = ({ bay }) => {

    const [loadStatus, setLoadStatus] = useState("border");
    const { darkTheme } = useThemeContext();

    useEffect(() => {

        bay.filter(obj => obj.status === "Charging").length > 0 ? setLoadStatus("moving-border") : setLoadStatus("border")

        const intervalId = setInterval(() => {
            bay.filter(obj => obj.status === "Charging").length > 0 ? setLoadStatus("moving-border") : setLoadStatus("border")

        }, 10000);
        return () => clearInterval(intervalId);


    }, []);

    const getBorderClass = () => {
        // This is the top line on infra tree
        return darkTheme ? "border-dark" : bay.filter(obj => obj.status === "Charging").length > 0 ? "moving-border" : "border"
        // return bay.filter(obj => obj.status === "Charging").length > 0 ? "moving-border" : "border"

    }

    // console.log(bay)

    // const noOfChargers = 29;

    const count = bay.length + bay.length / 10;

    const getBorderRight = (index) => {
        // console.log(bay.length);
        if (bay.length - index > 5 && index % 10 === 5)
            return "2px dotted";
        else
            return 0;


    }

    const TopBorder = ({ index }) => {
        if (index % 10 === 1 && index === bay.length)
            return <div></div>
        else if (index % 10 === 0 || index === bay.length)
            return <div><Grid style={{ borderTop: "2px dotted ", borderColor: darkTheme ? "#F2E34C" : "black" }} width={"50%"} /></div>
        else if (index % 10 === 1)
            return <Grid style={{ width: "100%", display: "flex", justifyContent: "flex-end", alignItems: 'flex-end' }}>
                <div style={{ width: "50%", borderTop: "2px dotted", borderTopColor: darkTheme ? '#F2E34C' : '#000' }} />
            </Grid>
        else return <div><Grid style={{ borderTop: "2px dotted", borderTopColor: darkTheme ? '#F2E34C' : '#000' }} /></div>

    }


    return (
        <div className="charger-tree" style={{ width: "100%", backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }}>
            <Grid container padding={2} minHeight={"50vh"} style={{ width: "100%", backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} alignItems={"center"} justifyContent={"center"}>
                <Grid item md={12} lg={12} xs={12} xl={12} style={{ padding: 0, margin: 0, backgroundColor: darkTheme ? '#333333' : '#f9f9f9' }} container alignItems={"center"} justifyContent={"center"}>
                    <Grid item md={2} lg={2} xs={2} xl={2}>

                        {" "}
                    </Grid>
                    <Grid item md={1} lg={1} xs={1} xl={1}>

                        <div style={{ width: '100%', border: "2px solid", borderColor: darkTheme ? '#F2E34C' : '#000', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img style={{ width: '100%' }} src="/assets/images/logo-full.png" alt="Logo" />
                        </div>
                    </Grid>
                    <Grid item md={2} lg={2} xs={2} xl={2} marginLeft={"10px"}>

                        <BarChart />
                    </Grid>


                </Grid>

                {
                    bay ?
                        <Grid item lg={12} md={12} xs={12} xl={12} style={{ padding: 0, margin: 0 }} container alignItems={"center"} justifyContent={"center"}>
                            <span className={getBorderClass()} style={{ height: "50px" }} />
                        </Grid>
                        : ""
                }

                {bay ? bay.map((element, index) => (
                    <Grid lg={1.2} md={1.2} xs={1.2} xl={1.2} style={{ paddingBottom: "20px", borderRight: getBorderRight(index + 1), borderColor: darkTheme ? '#F2E34C' : 'black' }}>


                        <TopBorder index={index + 1} />

                        {/* {index + 1} */}
                        {/* {getBorderRight(index+1)} */}
                        <Charger element={element} index={index} darkTheme={darkTheme} />
                    </Grid >


                )) : ""}

            </Grid>

        </div>
    );

}
export default ChargerTree;