// import * as React from 'react';
// import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import { useLocation, useNavigate } from "react-router-dom";
// import DashboardIcon from '@mui/icons-material/Dashboard';
// import MonitorIcon from '@mui/icons-material/Monitor';
// import AnalyticsIcon from '@mui/icons-material/Analytics';
// import AssessmentIcon from '@mui/icons-material/Assessment';
// import SourceIcon from '@mui/icons-material/Source';
// import { isNull } from 'lodash';
// import { useThemeContext } from 'app/ThemeProvider';

// export default function LabTabs() {
//   const [value, setValue] = React.useState('1');
//   const navigate = useNavigate();
//   const [navList, setNavList] = React.useState([]);
//   const [tabLabels,setTabLabels]=React.useState([]);
//   const { darkTheme } = useThemeContext();
//   const permission=JSON.parse(localStorage.getItem("permission"));
//   const handleChange = (tab) => {
//     setValue(tab.id);
//     navigate(tab.link);
//   };

//   React.useEffect(() => {
//     debugger;
//     if (navList.length === 0&&localStorage.getItem("permission"))
//         setNavList(JSON.parse(localStorage.getItem("permission"))
//         .filter(obj => obj.type === 'navigation')
//         .map(obj => obj.name))

//     else{
//       const tabLabels = []

//       navList.includes('Dashboard')&& tabLabels.push({ id: '1', label: 'Dashboard', link: '/dashboard/default', img: <DashboardIcon style={{ marginLeft: 25 }} /> })
//       navList.includes('Monitor')&& tabLabels.push({ id: '2', label: 'Monitor', link: '/charger/default', img: <MonitorIcon style={{ marginLeft: 15 }} /> })
//       navList.includes('Infra')&& tabLabels.push({ id: '4', label: 'Infra', link: '/depoView/default', img: <AssessmentIcon style={{ marginLeft: 5 }} /> })
//       // { id: '3', label: 'Events' ,link:'/cmsmgmt/evets',img:<EventIcon style={{marginLeft:15}}/>},
//       navList.includes('Logs')&& tabLabels.push({ id: '5', label: 'Logs', link: '/reports/default', img: <SourceIcon style={{ marginLeft: 5 }} /> })
//       navList.includes('Analytics')&& tabLabels.push({ id: '6', label: 'Analytics', link: '/analytics/default', img: <AnalyticsIcon style={{ marginLeft: 15 }} /> })
//       console.log(tabLabels);
//       setTabLabels(tabLabels);
    
//     }
//   }, [navList.length])


//   const location = useLocation();

//   // Access the pathname (endpoint) from the location object

//   return (
//     <Box sx={{ width: '100%', typography: 'body1' }}>
//       <TabContext value={value}>
//         <Box sx={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', borderColor: 'divider' }}>
//           <TabList onChange={handleChange} aria-label="lab API tabs example">
//               {tabLabels.map(tab => (
//                 <>
                
//                 <div
//                   key={tab.id}
//                   onClick={() => handleChange(tab)}
//                   style={{
//                     fontSize:15,
//                     // marginTop:20,
//                     // marginRight:20,
//                     // marginBottom:20,
//                     // marginLeft:'2%',
//                     zIndex:0,
//                     padding:"10px",
//                     cursor: 'pointer',
//                     backgroundColor: darkTheme ? (location.pathname === tab.link ? 'black' : 'black') : (location.pathname === tab.link ? 'white' : 'white'),
//         color: darkTheme ? (location.pathname === tab.link ? '#F2E34C' : 'grey') : (location.pathname === tab.link ? 'black' : 'grey')
//       }}

//                 >
//                   <Stack>
//                   {tab.img}
//                   {tab.label}
//                   </Stack>
//                 </div>
//               </>
//             ))}
//           </TabList>
//         </Box>
//       </TabContext>
//     </Box>
//   );
// }
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from '@mui/icons-material/Dashboard';
import MonitorIcon from '@mui/icons-material/Monitor';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SourceIcon from '@mui/icons-material/Source';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';

import { isNull } from 'lodash';
import { useThemeContext } from 'app/ThemeProvider';

export default function LabTabs() {
  const [value, setValue] = React.useState('1');
  const navigate = useNavigate();
  const [navList, setNavList] = React.useState([]);
  const [tabLabels,setTabLabels]=React.useState([]);
  const { darkTheme } = useThemeContext();
  const [permission,setPermision]=React.useState([]);

  React.useEffect(()=>{

    const fetchPermission=()=>{
      if(JSON.parse(localStorage.getItem("permission")))
      setPermision(JSON.parse(localStorage.getItem("permission")));
    }

    fetchPermission();

      const intervalId = setInterval(fetchPermission, 3000);
      return () => clearInterval(intervalId);

  },[])
  const handleChange = (tab) => {
    setValue(tab.id);
    navigate(tab.link);
  };

  React.useEffect(() => {
    debugger;
    if (navList.length === 0&&permission)

      setNavList(permission
        .filter(obj => obj.type === 'navigation')
        .map(obj => obj.name))

    else{
      const tabLabels = []

      navList.includes('Dashboard')&& tabLabels.push({ id: '1', label: 'Dashboard', link: '/dashboard/default', img: <DashboardIcon  /> })
      navList.includes('Monitor')&& tabLabels.push({ id: '2', label: 'Monitor', link: '/charger/default', img: <MonitorIcon  /> })
      navList.includes('Infra')&& tabLabels.push({ id: '4', label: 'Infra', link: '/depoView/default', img: <AssessmentIcon /> })
      // { id: '3', label: 'Events' ,link:'/cmsmgmt/evets',img:<EventIcon style={{marginLeft:15}}/>},
      navList.includes('Reports')&& tabLabels.push({ id: '5', label: 'Reports', link: '/reports/default', img: <SourceIcon  /> })
      navList.includes('Analytics')&& tabLabels.push({ id: '6', label: 'Analytics', link: '/analytics/default', img: <AnalyticsIcon  /> })
      navList.includes('Vehicles')&& tabLabels.push({ id: '7', label: 'Vehicles', link: '/vehicle/default', img: <DirectionsBusIcon  /> })

      navList.includes('DeviceMeter')&& tabLabels.push({ id: '8', label: 'EMS', link: '/device-meter/default', 

        img: <div style={{width:'1.4rem',paddingTop:'2px'}}>
          <img 
        src="/ems_icon.png" width="100%" 
        style={{ display: 'block', margin: 'auto',
            // borderRadius: "10px",
            }} />
        </div> })

      console.log(tabLabels);
      setTabLabels(tabLabels);
    
    }
  }, [navList.length,permission.length]);


  const location = useLocation();

  // Access the pathname (endpoint) from the location object

  return (
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ backgroundColor: darkTheme ? '#000' : '#f9f9f9', color: darkTheme ? '#fff' : '#000', borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example" sx={{justifyContent:'left'}}>
              {tabLabels.map(tab => (
                <>
                  <div
                    key={tab.id}
                    onClick={() => handleChange(tab)}
                    style={{
                      fontSize: 15,
                      // marginTop:20,
                      // marginRight:20,
                      // marginBottom:20,
                      // marginLeft:'2%',
                      zIndex: 0,
                      padding:"8px",
                      cursor: 'pointer',
                      // backgroundColor: darkTheme ? (location.pathname === tab.link ? 'black' : 'black') : (location.pathname === tab.link ? '#f9f9f9' : '#f9f9f9'),
                      backgroundColor: darkTheme ? (location.pathname === tab.link ? 'black' : 'black') : (location.pathname === tab.link ? '#f9f9f9' : '#f9f9f9'),
                      opacity: darkTheme ? (location.pathname === tab.link ? '1' : '0.5') : (location.pathname === tab.link ? '1' : '0.5')
                      }}
                  >
                  <Stack style={{margin:'auto',alignItems:'center'}}>
                  {tab.img}
                  {tab.label}
                  </Stack>
                </div>
              </>
            ))}
          </TabList>
        </Box>
      </TabContext>
    </Box>
  );
}
  
