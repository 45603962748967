import axios from 'axios';

// let headers = {
//   Accept: 'application/json',
// };
const axiosInstance = axios.create({
// baseURL: 'http://192.168.1.231:8091/EV',

// baseURL: 'http://122.160.48.7:8095/ED',
baseURL: 'https://cms-app.ev-opt.com/ED',

// baseURL: 'http://122.160.48.7:8091/ED',
//  baseURL:   'http://localhost:8093/ED',
//  baseURL: 'http://3.6.162.54:8091/EV',
  // baseURL: 'http://192.168.1.123:8093/ED',
  // baseURL: 'https://ocppmgl.ev-opt.com/EV',


  headers: { depoId: localStorage.getItem("selected_depot"),
  },

  timeout:120000
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong!')
);
export default axiosInstance;
