import React, { useState, useRef, useEffect } from 'react';
import img from './Desktop - 1 (14).jpg';
import './App.css';
import { Tooltip } from 'recharts';
import { Box, Button, Grid, IconButton, Modal, Paper, Typography } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import SimulatorApi from "app/views/simulation/SimulatorApi";
import EvStationIcon from '@mui/icons-material/EvStation';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Img from './meter (1).png'
import MeterDetail from 'app/views/depoView/components/meter-detail/MeterDetail';
import Chargeroo from './SC_Charger_oncharging.png'
import Chargeroo1 from './cccc.png'
import Building from './facility-management.png'
import Utilization from 'app/views/Analytics/shared/UtilizationTable.jsx'
import Statics from './Statics'
import Mccb from './Screenshot 2024-09-21 at 1.55.18 PM.png'
import DG from './image-removebg-preview (1).png'
const initialPositions = [
  { x: 50, y: 10, y1: 400, x1: 165 },
  { x: 50, y: 10, y1: 400, x1: 420 },
  { x: 50, y: 10, y1: 400, x1: 670 },
  { x: 50, y: 10, y1: 400, x1: 923 },
  { x: 50, y: 10, y1: 400, x1: 1180 },
  { x: 50, y: 10, y1: 400, x1: 1370 },
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1300,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
function Imagee({ Charger }) {
  const [iconPositions, setIconPositions] = useState(initialPositions);
  const canvasRef = useRef(null);
  const containerRef = useRef(null);



  useEffect(() => {
    const handleResize = () => {
      updateIconPositions();

    };
    window.addEventListener('resize', handleResize);
    updateIconPositions();

    drawCanvas();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const updateIconPositions = () => {
    const container = containerRef.current;
    if (!container) return;

    const width = container.clientWidth;
    const height = container.clientHeight;

    const newPositions = initialPositions.map(pos => ({
      x: (pos.x / 1400) * width,
      y: (pos.y / 500) * height,
      x1: (pos.x1 / 1400) * width,
      y1: (pos.y1 / 500) * height
    }));

    setIconPositions(newPositions);
  };




  const navigate = useNavigate();


  const [APIData, setAPIData] = useState([]);
  const api = new SimulatorApi();
  const search = useLocation().search;

  const sim_type = new URLSearchParams(search).get("sim_type");
  const sel_vehicle = new URLSearchParams(search).get("vehicle");
  const [animateBorder, setAnimateBorder] = useState(false);
  const [line, setline] = useState([0, 0, 0, 0, 0, 0, 0])

  useEffect(() => {
    drawCanvas();

    // Re-draw the canvas on window resize for responsiveness
    const handleResize = () => drawCanvas();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (sim_type === "single_v") {
      showSingleChargerGraph(sel_vehicle);
    } else {
      showChargerStatusGraph();
    }
    const interval = setInterval(() => {
      if (sim_type === "single_v") {
        showSingleChargerGraph(sel_vehicle);
      } else {
        showChargerStatusGraph();
      }
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  const showSingleChargerGraph = (vehicleId) => {
    api
      .showSingleChargerStatusGraph(null, vehicleId)
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log("Cannot load event data", e);
      });
  };

  const showChargerStatusGraph = () => {
    debugger;
    api
      .showChargerStatusGraph()
      .then((response) => {
        setAPIData(response.data);
      })
      .catch((e) => {
        // console.log("Cannot load event data", e);
      });
  };


  console.log(APIData, 'cnd')
  const statusColor = (element) => {
    if (element === "Charging") {
      return '#008001';
    }
    if (element === "Preparing") {
      return 'rgb(218, 165, 32)';
    }
    if (element === "Finishing") {
      return 'rgb(218, 165, 32)';
    }
    if (element === "Reserved") {
      return 'rgb(75, 192, 192)';
    }
    if (element === "Unavailable") {
      return '#878e98';
    }
    if (element === "Fault") {
      return '#ff5633';
    } else {
      return '#4169e1';
    }
  };
  const [Lome, setLome] = useState([0, 0, 0, 0, 0]);
  const offset1Ref = useRef(0);
  const offset3Ref = useRef(0);
  const offset4Ref = useRef(0);
  const offset5Ref = useRef(0);
  const offset6Ref = useRef(0);
  const offset7Ref = useRef(0);
  const LomeRef = useRef(Lome);


  useEffect(() => {
    LomeRef.current = Lome;
  }, [Lome.length]);


  useEffect(() => {
    if (APIData.length > 0 && APIData[0].length === Lome.length) {
      const updatedLome = [...Lome]; // Create a copy of Lome
      APIData[0].forEach((item, index) => {
        if (item.status === 'Charging') {
          updatedLome[index] = 0.7;
          console.log(item.charger_id, 'Charging', Lome);
        } else {
          updatedLome[index] = 0;
          console.log(item.charger_id, 'Not Charging', Lome);
        }
      });
      setLome(updatedLome); // Update the state
    }
  }, [APIData.length, Lome.length]);

  const drawCanvas = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const container = containerRef.current;

    if (container && canvas) {
      const width = container.clientWidth;
      const height = container.clientHeight;
      canvas.width = width;
      canvas.height = height;

      const mainImage = new Image();
      mainImage.src = img;

      mainImage.onload = () => {
        // Independent offsets for each line to regulate their speeds
        let offset1 = 0;
        let offset2 = 0;
        let offset3 = 0;
        let offset4 = 0;
        let offset5 = 0;
        let offset6 = 0;
        let offset7 = 0;

        // Function to draw all dashed lines
        function drawDashedLines() {
          // Clear the canvas once per frame
          ctx.clearRect(0, 0, canvas.width, canvas.height);

          // Draw the image
          ctx.drawImage(mainImage, 0, 0, width, height);

          
        }

        function animate() {
          // Update offset values based on Lome
          if (Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0) {
            offset1Ref.current += 0.5;
          }
          if (Lome[1] > 0) {
            offset3Ref.current += 0.5;
          }
          if (Lome[0] > 0) {
            offset4Ref.current += 0.5;
          }
          if (Lome[2] > 0) {
            offset5Ref.current += 0.5;
          }
          if (Lome[3] > 0) {
            offset6Ref.current += 0.5;
          }
          if (Lome[4] > 0) {
            offset7Ref.current += 0.5;
          }
          debugger;
          // console.log(Lome, 'jnj')
          drawDashedLines(); // Draw lines
          requestAnimationFrame(animate); // Continue animation
        }

        animate(); // Start animation after the image loads
      };
    }
  };
  const elementRef = useRef(null);

  const enterFullScreen = () => {
    if (elementRef.current.requestFullscreen) {
      elementRef.current.requestFullscreen();
    } else if (elementRef.current.mozRequestFullScreen) { // For Firefox
      elementRef.current.mozRequestFullScreen();
    } else if (elementRef.current.webkitRequestFullscreen) {
      elementRef.current.webkitRequestFullscreen();
    } else if (elementRef.current.msRequestFullscreen) {
      elementRef.current.msRequestFullscreen();
    }
  };
  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [fullscreen, setfullscreen] = useState(true)
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Grid xs={12} container padding={1} direction={'row'} ref={elementRef}>
      <Grid width={'100%'} item >
        <Paper elevation={3}>
          <div ref={containerRef} style={{ position: 'relative', width: 1100, height: 843, padding: 20, }}>
            <canvas ref={canvasRef} style={{ width: 1100, height: '100%' }} />
            <IconButton
              onClick={() => {
                setfullscreen(!fullscreen);
                if (!fullscreen) {
                  exitFullScreen();
                } else {
                  enterFullScreen();
                }
              }}
              style={{
                width: 40,
                position: 'absolute',
                left: '96vw',
                top: 15,
              }}
            >
              {fullscreen ? <FullscreenIcon /> : <FullscreenExitIcon />}
            </IconButton>

            <Grid style={{
              position: 'absolute',
              left: initialPositions[0].x1 - 110,
              top: initialPositions[0].y1 - 340
            }} xs={1} >
              {/* <BarChart /> */}
            </Grid>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-below" : 'none'}
              style={{
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                height: 130,
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 - 40,
                top: initialPositions[0].y1 - 150,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-right" : 'none'}
              style={{
                height: 5,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 145,
                zIndex: 5,
                left: initialPositions[0].x1 - 40,
                top: initialPositions[0].y1 - 20,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-above" : 'none'}
              style={{
                height: 65,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 + 105,
                top: initialPositions[0].y1 - 80,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-above" : 'none'}
              style={{
                height: 25,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 + 105,
                top: initialPositions[0].y1 - 180,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-above" : 'none'}
              style={{
                height: 35,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 + 105,
                top: initialPositions[0].y1 - 290,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-right" : 'none'}
              style={{
                height: 5,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 80,
                zIndex: 5,
                left: initialPositions[0].x1 + 105,
                top: initialPositions[0].y1 - 290,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-right" : 'none'}
              style={{
                height: 5,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 130,
                zIndex: 5,
                left: initialPositions[0].x1 + 253,
                top: initialPositions[0].y1 - 290,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 60,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 + 383,
                top: initialPositions[0].y1 - 290,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 20,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 + 383,
                top: initialPositions[0].y1 - 135,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 45,
                border: Lome[0] > 0 || Lome[1] > 0 || Lome[2] > 0 || Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 + 383,
                top: initialPositions[0].y1 - 40,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 || Lome[1] > 0 ? "moving-border-left" : 'none'}
              style={{
                border: Lome[0] > 0 || Lome[1] > 0 ? 'none' : '2.5px dashed black',
                height: 5,
                width: 198,
                zIndex: 5,
                left: initialPositions[0].x1 + 190,
                top: initialPositions[0].y1 + 5,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 ? "moving-border-left" : 'none'}
              style={{
                border: Lome[0] > 0 ? 'none' : '2.5px dashed black',
                height: 5,
                width: 190,
                zIndex: 5,
                left: initialPositions[0].x1,
                top: initialPositions[0].y1 + 5,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[3] > 0 || Lome[4] > 0 ? "moving-border-right" : 'none'}
              style={{
                height: 5,
                border: Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 + 388,
                top: initialPositions[0].y1 + 5,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[3] > 0 || Lome[4] > 0 ? "moving-border-right" : 'none'}
              style={{
                height: 5,
                border: Lome[3] > 0 || Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 200,
                zIndex: 5,
                left: initialPositions[0].x1 + 393,
                top: initialPositions[0].y1 + 5,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[4] > 0 ? "moving-border-right" : 'none'}
              style={{
                height: 5,
                border: Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 197,
                zIndex: 5,
                left: initialPositions[0].x1 + 590,
                top: initialPositions[0].y1 + 5,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[5] > 0 ? "moving-border-right" : 'none'}
              style={{
                height: 5,
                border: Lome[5] > 0 ? 'none' : '2.5px dashed black',
                width: 232,
                zIndex: 5,
                left: initialPositions[0].x1 + 787,
                top: initialPositions[0].y1 + 5,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 35,
                border: Lome[0] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 - 5,
                top: initialPositions[0].y1 + 5,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[1] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 30,
                border: Lome[1] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[1].x1 - 65,
                top: initialPositions[1].y1 + 10,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[2] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 30,
                border: Lome[2] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[2].x1 - 117,
                top: initialPositions[2].y1 + 10,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[3] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 30,
                border: Lome[3] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[3].x1 - 172,
                top: initialPositions[3].y1 + 10,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[4] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 30,
                border: Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[4].x1 - 231,
                top: initialPositions[4].y1 + 10,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[0] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 100,
                border: Lome[0] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[0].x1 - 5,
                top: initialPositions[0].y1 + 115,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[1] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 100,
                border: Lome[1] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[1].x1 - 65,
                top: initialPositions[1].y1 + 115,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[2] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 100,
                border: Lome[2] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[2].x1 - 117,
                top: initialPositions[2].y1 + 115,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[3] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 100,
                border: Lome[3] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[3].x1 - 172,
                top: initialPositions[3].y1 + 115,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[4] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 100,
                border: Lome[4] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[4].x1 - 231,
                top: initialPositions[4].y1 + 115,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[5] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 30,
                border: Lome[5] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[5].x1 - 191,
                top: initialPositions[5].y1 + 10,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[5] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 100,
                border: Lome[5] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[5].x1 - 191,
                top: initialPositions[5].y1 + 115,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[5] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 30,
                border: Lome[5] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[4].x1 - 191,
                top: initialPositions[4].y1 - 25,
                position: 'absolute'
              }}
            ></div>
            <div
              className={Lome[5] > 0 ? "moving-border-below" : 'none'}
              style={{
                height: 120,
                border: Lome[5] > 0 ? 'none' : '2.5px dashed black',
                width: 5,
                zIndex: 5,
                left: initialPositions[4].x1 - 191,
                top: initialPositions[4].y1 - 220,
                position: 'absolute'
              }}
            ></div>
            <img width={30} height={80} src={Mccb} style={{

              zIndex: 5,
              left: initialPositions[5].x1 - 202.5,
              top: initialPositions[5].y1 + 35,
              position: 'absolute'
            }} />

            <img width={30} height={80} src={Mccb} style={{

              zIndex: 5,
              left: initialPositions[4].x1 - 204,
              top: initialPositions[4].y1 - 110,
              position: 'absolute'
            }} />
            <img width={80} height={80} src={Building} style={{

              zIndex: 5,
              left: initialPositions[5].x1 - 230,
              top: initialPositions[5].y1 + 245,
              position: 'absolute'
            }} />
            <Typography style={{
              borderRadius: 35,
              fontSize: 11,
              position: 'absolute',
              width: 200,
              left: iconPositions[5].x1 + 65,
              top: iconPositions[5].y1 - 55,
              padding: 0,
              fontWeight: 'bold',
            }}>
              Facility Load
            </Typography>
            <Typography style={{
              borderRadius: 35,
              fontSize: 11,
              position: 'absolute',
              width: 200,
              left: initialPositions[4].x1 - 220,
              top: initialPositions[4].y1 - 240,
              padding: 0,
              fontWeight: 'bold',
            }}>
              Energy Storage
            </Typography>
            <img width={80} height={80} src={DG} style={{
              height: 80, width: 80,
              position: 'absolute',
              left: initialPositions[4].x1 - 230,
              top: initialPositions[4].y1 - 300,
            }} />
            {APIData.length > 0 ?
              APIData[0].map((item, index) => (

                <React.Fragment key={index}>
                  {item.status === 'Charging' ?
                    <>

                      <div style={{
                        height: 20, width: 60,
                        position: 'absolute',
                        left: iconPositions[index].x1 - 40,
                        top: iconPositions[index].y1 + 115,
                        zIndex: 5,
                        border: '2px solid black',
                        color: 'white',
                        backgroundColor: item.current_soc<90?'#ff0000':'green',
                        fontSize: 10.5,
                        textAlign: 'center',
                        fontWeight: 'bolder'
                      }}>
                        {
                          item.current_soc<90?"CP MODE":"CV MODE"
                        }
                        
                      </div>
                    </>
                    : null}
                  <div style={{
                    height: 10, width: 50, color: statusColor(item.status),
                    position: 'absolute',
                    left: item.status !== 'Charging' ? iconPositions[index].x1 : iconPositions[index].x1 - 30,
                    top: iconPositions[index].y1 + 35,
                    zIndex: 5,
                    backgroundColor: statusColor(item.status)
                  }}></div>
                  <img width={80} height={80} src={item.status === 'Charging' ? Chargeroo : Chargeroo1} style={{
                    height: 80, width: 80, color: statusColor(item.status),
                    position: 'absolute',
                    left: item.status !== 'Charging' ? iconPositions[index].x1 - 15 : iconPositions[index].x1 - 25,
                    top: iconPositions[index].y1 - 30,
                  }} />
                  {item.bus_image.charAt(0) == 'b' ?
                    <img width={80} height={80} src={`/bus_icons/${item.bus_image.charAt(0) == 'b' ? item.bus_image : null}`} style={{
                      position: 'absolute',
                      left: iconPositions[index].x1 + 25,
                      top: iconPositions[index].y1 - 30,
                    }} /> : null}
                  <Typography style={{
                    borderRadius: 35,
                    fontSize: 11,
                    position: 'absolute',

                    left: iconPositions[index].x1 - 15,
                    top: iconPositions[index].y1 - 55,
                    padding: 0,
                    fontWeight: 'bold',
                  }}>
                    {item.charger_id}
                  </Typography>
                  {item.status === 'Charging' ?
                    <>
                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',
                        left: iconPositions[index].x1 - 40,
                        top: iconPositions[index].y1 + 65,
                        padding: 0,
                        fontWeight: 'bold',
                      }}>
                        Power : {item['power']} kW
                      </Typography>
                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',

                        left: iconPositions[index].x1 - 40,
                        top: iconPositions[index].y1 + 80,
                        padding: 0,
                        fontWeight: 'bold',
                      }}>
                        Current : {item['Current.Import-Outlet']} A
                      </Typography>
                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',
                        left: iconPositions[index].x1 - 40,
                        top: iconPositions[index].y1 + 95,
                        padding: 0,
                        fontWeight: 'bold',
                      }}>
                        Voltage : {item['Voltage-Outlet']} V
                      </Typography>
                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',
                        left: iconPositions[index].x1 + 30,
                        top: iconPositions[index].y1 + 65,
                        padding: 0,
                        fontWeight: 'bold',
                      }}>
                        Start SoC : -
                      </Typography>
                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',
                        left: iconPositions[index].x1 + 30,
                        top: iconPositions[index].y1 + 80,
                        padding: 0,
                        fontWeight: 'bold',
                      }}>
                        Target SoC: -
                      </Typography>
                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',
                        left: iconPositions[index].x1 + 30,
                        top: iconPositions[index].y1 + 95,
                        padding: 0,
                        fontWeight: 'bold',
                      }}>
                        Power Demand EV:{item.charger_rating}A
                      </Typography>
                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',
                        left: iconPositions[index].x1 + 30,
                        top: iconPositions[index].y1 + 110,
                        padding: 0,
                        fontWeight: 'bold',
                      }}>
                        Current EV :{item.current_soc == undefined | item.current_soc === null ? '--' : `${item.current_soc}%`}V
                      </Typography>


                      <Typography style={{
                        borderRadius: 35,
                        fontSize: 11,
                        position: 'absolute',
                        left: iconPositions[index].x1 + 30,
                        top: iconPositions[index].y1 + 125,
                        padding: 0,
                        fontWeight: 'bold',

                      }}>
                        Voltage EV :{item.target_soc === undefined || item.target_soc === null ? '--' : `${item.target_soc}%`}
                      </Typography>
                    </>
                    : null}
                  <Typography style={{
                    borderRadius: 35,
                    fontSize: 12,
                    position: 'absolute',
                    left: item.status === 'Charging' ? iconPositions[index].x1 - 30 : iconPositions[index].x1,
                    top: iconPositions[index].y1 + 45,
                    padding: 0,
                    fontWeight: 'bold',
                    color: statusColor(item.status)
                  }}>
                    {item.status}
                  </Typography>

                  {item.vehicleLicensePlate ?
                    <Typography style={{
                      borderRadius: 5,
                      fontSize: 12,
                      position: 'absolute',
                      left: iconPositions[index].x1 + 30,
                      top: iconPositions[index].y1 + 45,
                      padding: 0,
                      fontWeight: 'bold',




                    }}>
                      {item.vehicleLicensePlate}

                    </Typography>

                    : null}
                  <div>
                    {/* <IconButton onClick={handleOpen}  style={{
          position: 'absolute',
          left: iconPositions[index].x1+50,
          top: iconPositions[index].y1-125 ,
        }}> */}
                    <img
                      key={index}
                      width={hoveredIndex === index ? 45 : 50}  // Change width on hover
                      height={hoveredIndex === index ? 45 : 50} // Change height on hover
                      src={Img}
                      onMouseEnter={() => setHoveredIndex(index)}  // Set hovered index
                      onMouseLeave={() => setHoveredIndex(null)}   // Reset hovered index on leave
                      style={{
                        position: 'absolute',
                        left: iconPositions[index].x1 + 50,
                        top: iconPositions[index].y1 - 125,
                      }}
                      onClick={handleOpen}
                    />
                    <Typography style={{
                      borderRadius: 35,
                      fontSize: 11,
                      position: 'absolute',
                      left: iconPositions[index].x1 + 100,
                      top: iconPositions[index].y1 - 120,
                      padding: 0,
                      fontWeight: 'bold',

                    }}>
                      kWh
                    </Typography>
                    <Typography style={{
                      borderRadius: 35,
                      fontSize: 9,
                      position: 'absolute',
                      left: iconPositions[index].x1 + 100,
                      top: iconPositions[index].y1 - 110,
                      padding: 0,
                      fontWeight: 'bold',

                    }}>
                      kW
                    </Typography>
                    <Typography style={{
                      borderRadius: 35,
                      fontSize: 9,
                      position: 'absolute',
                      left: iconPositions[index].x1 + 100,
                      top: iconPositions[index].y1 - 100,
                      padding: 0,
                      fontWeight: 'bold',

                    }}>
                      A
                    </Typography>
                    {/* </IconButton> */}
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      BackdropProps={{
                        style: {
                          backgroundColor: 'rgba(0, 0, 0, 0.2)', // Change the background color and transparency here
                        },
                      }}
                    >
                      <Box sx={style}>
                        <MeterDetail />
                      </Box>
                    </Modal>
                  </div>

                </React.Fragment>
              )) : null}
            <div>
              {/* <IconButton onClick={handleOpen}  style={{
          position: 'absolute',
          left: iconPositions[index].x1+50,
          top: iconPositions[index].y1-125 ,
        }}> */}
              <img
                key={5}
                width={hoveredIndex === 5 ? 45 : 50}  // Change width on hover
                height={hoveredIndex === 5 ? 45 : 50} // Change height on hover
                src={Img}
                onMouseEnter={() => setHoveredIndex(5)}  // Set hovered index
                onMouseLeave={() => setHoveredIndex(null)}   // Reset hovered index on leave
                style={{
                  position: 'absolute',
                  left: iconPositions[5].x1 + 130,
                  top: iconPositions[5].y1 - 125,
                }}
                onClick={handleOpen}
              />
              <Typography style={{
                borderRadius: 35,
                fontSize: 11,
                position: 'absolute',
                left: iconPositions[5].x1 + 180,
                top: iconPositions[5].y1 - 120,
                padding: 0,
                fontWeight: 'bold',

              }}>
                kWh
              </Typography>
              <Typography style={{
                borderRadius: 35,
                fontSize: 9,
                position: 'absolute',
                left: iconPositions[5].x1 + 180,
                top: iconPositions[5].y1 - 110,
                padding: 0,
                fontWeight: 'bold',

              }}>
                kW
              </Typography>
              <Typography style={{
                borderRadius: 35,
                fontSize: 9,
                position: 'absolute',
                left: iconPositions[5].x1 + 180,
                top: iconPositions[5].y1 - 100,
                padding: 0,
                fontWeight: 'bold',

              }}>
                A
              </Typography>
              {/* </IconButton> */}
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                  style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)', // Change the background color and transparency here
                  },
                }}
              >
                <Box sx={style}>
                  <MeterDetail />
                </Box>
              </Modal>
            </div>
            <div>

              <img
                key={6}
                width={hoveredIndex === 6 ? 45 : 50}
                height={hoveredIndex === 6 ? 45 : 50}
                src={Img}
                onMouseEnter={() => setHoveredIndex(6)}
                onMouseLeave={() => setHoveredIndex(null)}
                style={{
                  position: 'absolute',
                  left: iconPositions[4].x1 + 100,
                  top: iconPositions[4].y1 - 460,
                }}
                onClick={handleOpen}
              />
              <Typography style={{
                borderRadius: 35,
                fontSize: 11,
                position: 'absolute',
                left: iconPositions[4].x1 + 160,
                top: iconPositions[4].y1 - 420 - 30,
                padding: 0,
                fontWeight: 'bold',

              }}>
                kWh
              </Typography>
              <Typography style={{
                borderRadius: 35,
                fontSize: 9,
                position: 'absolute',
                left: iconPositions[4].x1 + 160,
                top: iconPositions[4].y1 - 410 - 30,
                padding: 0,
                fontWeight: 'bold',

              }}>
                kW
              </Typography>
              <Typography style={{
                borderRadius: 35,
                fontSize: 9,
                position: 'absolute',
                left: iconPositions[4].x1 + 160,
                top: iconPositions[4].y1 - 400 - 30,
                padding: 0,
                fontWeight: 'bold',

              }}>
                A
              </Typography>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                BackdropProps={{
                  style: {
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  },
                }}
              >
                <Box sx={style}>
                  <MeterDetail />
                </Box>
              </Modal>
            </div>
          </div>
        </Paper>
      </Grid>

    </Grid>
  );
}

export default Imagee;



